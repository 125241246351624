import React, { FunctionComponent } from 'react'
import { Header, Home, Footer, About, Education, Experience, Tools, Contact } from './sections'
import { Row, Col, Container } from 'react-bootstrap'
import ScrollTopArrow from './components/ScrollTopArrow'

import './components/Icons'
import 'bootstrap/dist/css/bootstrap.min.css'

const App: FunctionComponent = () => {
  return (
    <>
      <Header />
      <main>
        <Home />
        <About />
        <Container>
          <Row>
            <Col md={6}>
              <Education />
            </Col>
            <Col md={6}>
              <Experience />
            </Col>
          </Row>
        </Container>
        {/* <Services />
        <Review /> */}
        <Tools />
        <Contact />
      </main>
      <Footer />
      <ScrollTopArrow />
    </>
  )
}

export default App;
