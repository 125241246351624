import React, { FunctionComponent } from 'react'
import { Scroller } from '../components'
import { Container, Navbar, Nav } from 'react-bootstrap'
import styled from 'styled-components'
import details from '../data/details.json'

const Logo = styled.span({
  color: '#fff',
  fontSize: '36px',
  fontFamily: 'Rubik, sans-serif',
  fontWeight: 700,
  lineHeight: '36px'
})

const Dot = styled.span({
  color: '#ff4c60',
  display: 'inline'
})

export const Header: FunctionComponent = () => {
  return (
    <header className="header fixed-top">
      <Container>
        <Navbar expand="lg" variant="dark">
          <Navbar.Brand href="#">
            <Logo>
              {details.firstName}
              <Dot>{details.middleName}</Dot>
              {details.lastName}
            </Logo>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarNavDropdown" />
          <Navbar.Collapse className="justify-content-end" id="navbarNavDropdown">
            <Nav className="ml-auto" as="ul">
              <Nav.Item as="li">
                <Scroller href="#home" className="nav-link">Home</Scroller>
              </Nav.Item>
              <Nav.Item as="li">
                <Scroller href="#about" className="nav-link">About</Scroller>
              </Nav.Item>
              <Nav.Item as="li">
                <Scroller href="#education" className="nav-link">Education &amp; Experience</Scroller>
              </Nav.Item>
              <Nav.Item as="li">
                <Scroller href="#contact" className="nav-link">Contact</Scroller>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  )
}

export default Header
