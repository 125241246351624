import React, { FunctionComponent, useEffect } from 'react'
import smoothScroll from 'smoothscroll-polyfill'

interface Props {
  offset?: number
}

export const Scroller: FunctionComponent<Props & React.AnchorHTMLAttributes<HTMLAnchorElement>> = (props) => {
  useEffect(() => {
    smoothScroll.polyfill()
  });

  const onClickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    let offset = 0
    if (props.offset) {
      offset = props.offset
    }

    const id = e.currentTarget.getAttribute('href')?.slice(1)
    if (id) {
      const $anchor = document.getElementById(id)
      if ($anchor) {
        const offsetTop = $anchor.getBoundingClientRect().top + window.pageYOffset
        window.scroll({
          top: offsetTop - offset,
          behavior: 'smooth'
        })
      }
    }

    if (props.onClick) {
      props.onClick(e)
    }
  }

  const { offset, ...rest } = props
  return (
    <a {...rest} onClick={onClickHandler}>
      {props.children}
    </a>
  )
}

export default Scroller
