import React, { FunctionComponent, useState } from 'react'
import { EmailSent, Section } from '../components'
import { Row, Col, Button, Form } from 'react-bootstrap'
import contact from '../data/contact.json'
import emailjs from '@emailjs/browser';
declare type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

type Field = {
  value?: any,
  error?: string,
  isValid?: boolean
}

type Form = {
  name: Field,
  email: Field,
  subject: Field,
  message: Field
}

const initialField: Field = {
  value: "",
  isValid: undefined
};

const initialState: Form = {
  name: initialField,
  email: initialField,
  subject: initialField,
  message: initialField
};

export const Contact: FunctionComponent = () => {

  const [form, setForm] = useState<Form>(initialState)
  const [emailSent, setEmailSent] = useState<boolean>(false)

  const handleOnChange = (e: React.ChangeEvent<FormControlElement>) => {
    const fieldName: string = e.target.name;
    const fieldValue: string = e.target.value;
    const newField: Field = { [fieldName]: { value: fieldValue } };

    setForm({ ...form, ...newField });
  }

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const fieldName: string = e.target.name;
    const fieldValue: string = e.target.value;
    const newField: Field = validateField(fieldName, fieldValue);
    setForm({ ...form, ...newField });
  }

  const validateField = (fieldName: string, fieldValue: string) => {
    let error: string | undefined = undefined;
    let isValid: boolean | undefined = undefined;
    switch (fieldName) {
      case "name":
        if (fieldValue !== undefined && fieldValue !== '') {
          isValid = fieldValue.length >= 2;
        }
        if (isValid === false) {
          error = "Name must contain at least 2 characters"
        }
        break;
      case "email":
        if (fieldValue !== undefined && fieldValue !== '') {
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          isValid = re.test(fieldValue)
        }
        if (isValid === false) {
          error = "Email must have a valid format"
        }
        break;
      case "subject":
        if (fieldValue !== undefined && fieldValue !== '') {
          isValid = fieldValue.length >= 3;
        }
        if (isValid === false) {
          error = "Subject must contain at least 3 characters"
        }
        break;
      case "message":
        if (fieldValue !== undefined && fieldValue !== '') {
          isValid = fieldValue.length >= 10;
        }
        if (isValid === false) {
          error = "Message must contain at least 10 characters"
        }
        break;
      default:
        break;
    }
    let newField: Field = {
      [fieldName]: {
        value: fieldValue,
        isValid: isValid,
        error: error
      }
    };
    return newField;
  }

  const handleOnClick = () => {
    const isValidForm = form.name.isValid && form.subject.isValid && form.email.isValid && form.message.isValid;
    if (isValidForm) {
      sendMessage();
    }
  }

  const sendMessage = () => {
    const emailForm = {
      name: form.name.value,
      email: form.email.value,
      subject: form.subject.value,
      message: form.message.value
    };

    emailjs.send('service_wmp74vb', 'template_fjbwv9l', emailForm, 'user_txPfRtM7bKk7h7zrAo6jC')
      .then(function () {
        setEmailSent(true);
        setForm(initialState);
      });
  }

  return (
    <Section id="contact" title={emailSent === true ? contact.messageSent : "Get In Touch"}>
      {
        emailSent ?
          <Row>
            <Col md={8}>
              <EmailSent></EmailSent>
            </Col>
          </Row> :
          <Row>
            <Col md={4}>
              <div className="contact-info">
                <h3>{contact.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: contact.subTitle }} />
              </div>
            </Col>
            <Col md={8}>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="inputName">
                    <Form.Control name="name"
                      type="text"
                      className="form-control"
                      placeholder="Your name"
                      value={form.name.value}
                      isValid={form.name.isValid}
                      isInvalid={form.name.isValid !== undefined ? !form.name.isValid : undefined}
                      onChange={handleOnChange}
                      onBlur={handleOnBlur} />
                    <div className="invalid-feedback">
                      {form.name.error}
                    </div>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="inputEmail">
                    <Form.Control name="email"
                      type="email"
                      className="form-control"
                      placeholder="Email address"
                      value={form.email.value}
                      isValid={form.email.isValid}
                      isInvalid={form.email.isValid !== undefined ? !form.email.isValid : undefined}
                      onChange={handleOnChange}
                      onBlur={handleOnBlur} />
                    <div className="invalid-feedback">
                      {form.email.error}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group controlId="inputSubject">
                    <Form.Control name="subject"
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      value={form.subject.value}
                      isValid={form.subject.isValid}
                      isInvalid={form.subject.isValid !== undefined ? !form.subject.isValid : undefined}
                      onChange={handleOnChange}
                      onBlur={handleOnBlur} />
                    <div className="invalid-feedback">
                      {form.subject.error}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group controlId="inputMessage">
                    <Form.Control name="message"
                      as="textarea"
                      rows={5}
                      className="form-control"
                      placeholder="Message"
                      value={form.message.value}
                      isValid={form.message.isValid}
                      isInvalid={form.message.isValid !== undefined ? !form.message.isValid : undefined}
                      onChange={handleOnChange}
                      onBlur={handleOnBlur} />
                    <div className="invalid-feedback">
                      {form.message.error}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Button variant="contact" onClick={handleOnClick}>Send Message</Button>
                </Col>
              </Row>
            </Col>
          </Row>
      }
    </Section>
  )
}

export default Contact