import React, { FunctionComponent } from 'react'
import { Container } from 'react-bootstrap'

interface Props {
  id: string
  title: string
}

export const Section: FunctionComponent<Props> = ({id, title, children}) => {
  return (
    <section id={id}>
      <Container>
        <h2 className="section-title mb-5">{title}</h2>

        {children}
      </Container>
    </section>
  )
}

export default Section
